'use client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Carousel } from 'flowbite-react'
import React from 'react'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import PDF from '../../assets/pdf/ciessglobal.pdf'
import { LuDownload } from "react-icons/lu";

const SolutionsLayout = (props) => {
  const handleImage = (i) => {
    const pdfUrl = i === 0 ? props.data?.pdf[0]?.file : i === 1 ? props.data?.pdf[1]?.file : i === 2 ? props.data?.pdf[2]?.file : props.data?.pdf[3]?.file;
    const fileName = i === 0 ? 'Commercial&industrial.pdf' : 'Commercial&industrial.pdf';

    const newWindow = window.open(pdfUrl, '_blank');
    if (newWindow) {
      newWindow.document.title = fileName;
    }
  };
  return (
    <div className='min-h-screen w-full mt-24 lg:px-56 px-5 mb-5'>
      <h1 className='flex text-center justify-center text-2xl font-bold'>{props?.data?.title}</h1>
      <div className='mt-10 w-full'>
        {/* <span><a href='/solutions' className='mr-5 text-2xl font-bold'>SOLUTIONS</a><FontAwesomeIcon icon={faArrowRight} /><a href={`/solutions/${props.data?.link}`} className='ml-3 font-bold text-xl'>{props.data?.title}</a></span> */}
        <Breadcrumb className='font-black text-2xl text-center'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href='/solutions'>
            Solutions
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/${props.data?.parentLink}`}>
            {props.data.parent}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/${props.data?.link}`} style={{ fontSize: '50px' }}>
            {props.data?.title}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <div className='w-full lg:h-[500px] h-[300px]'>
        <Carousel
          slideInterval={3000}
          leftControl={<button className="text-blue-950 border-2 rounded-full lg:p-2 p-1 text-xs border-blue-950"><FaChevronLeft /></button>}
          rightControl={<button className="text-blue-950 border-2 rounded-full lg:p-2 p-1 text-xs border-blue-950"><FaChevronRight /></button>}
        >
          {props.data.images?.map((img, index) => (
            <img
              className={`${props.data.title === 'Commercial & Industrial ESS' ? 'max-w-[500px] max-h-[500px]' : "max-w-[700px] max-h-[500px]"} cursor-pointer`}
              key={index}
              alt={index}
              src={img}
              onClick={props.data.pdf ? () => handleImage(index) : null}
            />))}
        </Carousel>
      </div>
      {props.data.title === 'Commercial & Industrial ESS' &&
        <p className='text-center lg:text-sm text-xs'>
          Click on the image to download the catalogue
        </p>}
      <div className='w-full lg:mt-12 flex lg:flex-row flex-col'>
        <div className="flex-1">
          <h1 className='font-bold text-2xl lg:items-start items-center lg:mb-0 mb-5'>Introduction</h1>
        </div>
        <div className="flex-1">
          <p className='font-semibold text-l'>{props.data?.description}</p>
          {props.data.title === 'Commercial & Industrial ESS' &&
            <div className='w-full py-5 flex lg:flex-row flex-col'>
              <a href={PDF} download={'CIESS.pdf'} className='flex flex-row items-center gap-1'>
                <h2 className='font-bold text-l'>Download Full Catalogue</h2>
                <LuDownload size={20} />
              </a>
            </div>}
        </div>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      {props.data.feature &&
        <div>
          <h1 className='pt-10 font-bold text-2xl'>Features</h1>
          <div className='w-full mt-10 lg:items-start items-center'>
            <div className='flex lg:flex-row flex-col gap-10'>
              {props.data?.feature?.map((feat, i) => (
                <div className="flex-1" key={i}>
                  <div className='text-blue-800'><FontAwesomeIcon icon={feat?.icon} size='xl' className={`hover-${feat?.style}`} /></div>
                  <h2 className='font-semibold text-l pt-5'>{feat?.title}</h2>
                  <p className='text-sm pt-2.5'>{feat?.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      <div className='divider h-[1px] bg-black mt-8'></div>
      {props.data.scenarios &&
        <div>
          <h1 className='pt-10 font-bold text-2xl'>Scenarios</h1>
          <div className='w-full mt-10 lg:items-start items-center'>
            <div className='flex lg:flex-row flex-col gap-10'>
              {props.data?.scenarios?.map((scen, i) => (
                <div className="flex-1" key={i}>
                  <div className='text-blue-800'><FontAwesomeIcon icon={scen?.icon} size='xl' className={`hover-${scen?.style}`} /></div>
                  <h2 className='font-semibold text-l pt-5'>{scen?.title}</h2>
                  <p className='text-sm pt-2.5'>{scen?.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>}

      {props.data.title === 'Residential ESS' &&
        <div className='flex lg:flex-row flex-col justify-between text-center gap-5 py-7'>
          <a href='/solutions/batterystorage/residentialess/singlephase' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
            <p>Learn more about our Single Phase</p>
            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
          </a>
          <a href='/solutions/batterystorage/residential/threephase' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
            <p>Learn more about our Three Phase</p>
            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
          </a>
          {/* <a href='/solutions/batterystorage/residential/splitess' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
            <p>Learn more about our Split Ess</p>
            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
          </a> */}
        </div>}
    </div>
  )
}

export default SolutionsLayout