import { Breadcrumb } from 'flowbite-react'
import React from 'react'
import LOGO from '../assets/images/bescore.svg'
import { HiOutlineArrowRight } from 'react-icons/hi'

const BatteryStorage = () => {
  return (
    <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
      <h1 className='flex text-center justify-center text-3xl font-bold'>Battery Energy Storage</h1>
      <div className='mt-10'>
        <Breadcrumb className='font-black text-2xl'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions`} >
            Solutions
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/batterystorage`}>
            Battery Energy Storage
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <img src={LOGO} alt='logo' className='mx-auto max-h-16 mt-10 w-48' />
      <iframe className='w-full lg:h-96 mt-8' src="https://www.youtube.com/embed/yTmvdcEqPvI?si=2PbtE06SBiw_anuB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <div className='mt-10 flex flex-col'>
        <p className='font-semibold'>Specialized in clean energy solutions, BESCORE is one of leading energy storage solution and products provider in this field. Control the entire
          R&D, production and sales chain of battery energy storage system (ESS).<br /><br />
          The solutions cover Residential, Commercial, Industrial and Outdoor scenarios (Portable power station). Thanks to our intelligent and experienced engineer experts from worldwide, the system and hardware are well guaranteed and highly reputed for its stability reliable and safety.
          Based on advanced and independent R&D technology in inverters and batteries.<br /><br />
          We make it our purpose to satisfy our customers and partners worldwide a clean, reliable and affordable energy future, through the smart
          solutions that we offer.<br /><br />
          Always keep its high efficiency, reliable, safety, easy installation and low maintenance.
          Motivation and innovation are always rank first on our list.
        </p>
        <div className='flex lg:flex-row flex-col justify-between items-center gap-5 py-5'>
        <div className="flex justify-end py-2">
          <a href='/solutions/batterystorage/residentialess' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
            <p>Learn more about our Residential ESS</p>
            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
          </a>
        </div>
        <div className="flex justify-end py-2">
          <a href='/solutions/batterystorage/ciess' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
            <p>Learn more about our C&I ESS</p>
            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
          </a>
        </div>
      </div>
      </div>
    </div>
  )
}

export default BatteryStorage